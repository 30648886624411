<template lang='pug'>
	.d-flex.flex-column.project-buttons(:class="{'flex-column-reverse': ((!promoCode && !isPaymentNeurobots) || (isPaymentNeurobots && !isBtnBuyTokens)), 'border-dark' : isDarkTheme}")
		.d-flex.project-buttons__buttons-wrap.justify-content-between
			div(v-if="promoCode && !isPaymentNeurobots")
				span.btn.btn-medium.text-nowrap.project-buttons__button( v-html="$t('paymentPage.havePromoCode')" @click="$bvModal.show('add-promo-code')")

			div(v-if="isBtnBuyTokens && isPaymentNeurobots")
				span.btn.btn-medium.text-nowrap.project-buttons__button(@click="goToTokenPurchasePage") 💎 {{ $t('tokens.buyTokens') }}

			router-link(v-if="!isPaymentNeurobots" :to="{name: 'find'}" target='_blank')
				span.btn.btn-medium.text-nowrap.project-buttons__button(:class="{'project-buttons__button--full-width': !promoCode}" v-html="$t('paymentPage.noPromoCode')")
			div(v-if="isPaymentNeurobots")
				span.btn.btn-medium.text-nowrap.project-buttons__button(@click="$bvModal.show('no-access-to-bot')") 😥 {{ $t('paymentPage.noAccessToBot') }}

		.d-flex.mb-0.project-buttons__buttons-wrap.justify-content-between
			router-link(v-if="!isPaymentNeurobots" :to="{name: 'subscriber'}" target='_blank')
				span.btn.btn-medium.text-nowrap.project-buttons__button 💼 {{ $t('paymentPage.manageSubscription') }}
			div(v-if="isPaymentNeurobots")
				span.btn.btn-medium.text-nowrap.project-buttons__button(@click="$bvModal.show('manage-subscription')") 💼 {{ $t('paymentPage.manageSubscription') }}

			div
				span.btn.btn-medium.text-nowrap.project-buttons__button(v-html="$t('paymentPage.complain')" @click="$bvModal.show('complain')")
</template>

<script>
export default {
	name: 'ProjectButtons',
	props: {
		promoCode: {
			default: false,
			type: Boolean
		},
		isDarkTheme: {
			type: Boolean,
			default: false
		},
		isPaymentNeurobots: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		isBtnBuyTokens: true,
		isBuyTokens: false
	}),
	methods: {
		goToTokenPurchasePage() {
			this.isBuyTokens = true;
			window.scrollTo({ top: 0, behavior: "instant"});
			this.$emit('currencyRUB', 1)
		}
	},
	watch: {
		isBuyTokens(nV) {
			if (nV == 0) {
				this.$emit('isBuyTokens', false)
			} else if (nV == 1) {
				this.$emit('isBuyTokens', true)
			}
		}
	}
}
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.project-buttons {
	background-color: var(--bg-transparent-black-white-back) !important;
	border-radius: 8px 8px 0 0 !important;
	padding: 20px;

	@include respond-above(xm) {
		padding: 20px 18px 30px;
	}

	&.flex-column-reverse {
		flex-direction: column !important;

		@include respond-above(xm) {
			flex-direction: column-reverse !important;
		}
	}

	&.border-dark {
		border: 1px solid #444;

		.project-buttons__button {
			border: 1px solid #444 !important;

			&:hover,
			&:active {
				color: white !important;
				background-color: rgba(255, 255, 255, 0.55) !important;
				border: 1px solid transparent !important;
			}
		}
	}

	&__buttons-wrap {
		flex-wrap: wrap;

		@include respond-above(xm) {
			flex-wrap: no-wrap;
			margin: 0 0 16px;
		}

		&:last-child {
			margin-bottom: -10px !important;

			@include respond-above(xm) {
				margin-bottom: 0 !important;
			}
		}

		a {
			&:hover,
			&:active {
				-webkit-text-fill-color: inherit;
				text-decoration: none;
			}
		}

		a:has(.project-buttons__button), div:has(.project-buttons__button) {
			width: 100%;

			@include respond-above(xm) {
				width: calc(50% - 8px);
			}
		}

		a:has(.project-buttons__button--full-width), div:has(.project-buttons__button--full-width){
			@include respond-above(xm) {
				max-width: 100% !important;
				min-width: 100% !important;
				margin-top: 16px;
				margin-bottom: -16px;
			}
		}
	}

	&__button {
		color: white !important;
		background-color: var(--bg-project-button) !important;
		border: none !important;
		max-width: 100% !important;
		min-width: 100% !important;
		margin-bottom: 10px;

		&:hover,
		&:active {
			color: white !important;
			background-color: rgba(255, 255, 255, 0.55) !important;
		}

		@include respond-above(xm) {
			margin-bottom: 0;
		}
	}
}
</style>
