<template lang='pug'>
	.d-flex.justify-content-between.flex-wrap.buy-tokens
		template
			
			a.border-rounded.buy-tokens__can-do(href="https://docs.paywall.pw/academyai/tokeny" target="_blank" :class="{'is-dark': isDarkTheme}")
				span.b1.text-bold {{ $t('tokens.canYouDo') }}
				svg(width='24' height='24' viewbox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg')
					path(d='M8.91016 19.9201L15.4302 13.4001C16.2002 12.6301 16.2002 11.3701 15.4302 10.6001L8.91016 4.08008' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
			
			.buy-tokens__col
				.border-rounded.buy-tokens__wrap(:class="{'is-dark': isDarkTheme}")
					.buy-tokens__inner
						img.flex-shrink-0.buy-tokens__image.buy-tokens__image-1(src="/assets/img/tokens/tokens-1.webp" width="253" height="195" alt="Tokens")
						div
							span.d-block.h3.buy-tokens__quantity
								span {{ `${tokensOneTitle} ` }}
								span.buy-tokens__quantity-name {{ $t('tokens.quantityTokens') }}
							.d-flex.flex-wrap.align-items-center.buy-tokens__prices
								span.text-bold.buy-tokens__price(v-html="currencyRUB ? tokensOneRUB : tokensOneUSD")
								span.b1.buy-tokens__price-old
					span.btn.btn-primary.buy-tokens__button(@click="$emit('buy', {currencyRUB: currencyRUB, product: {name: `${tokensOneTitle} ${$t('tokens.quantityTokens')}`, cost: currencyRUB ? tokensOneRUB : tokensOneUSD}})") {{ $t('forms.buy') }}
			.buy-tokens__col
				.border-rounded.buy-tokens__wrap(:class="{'is-dark': isDarkTheme}")
					.buy-tokens__inner
						img.flex-shrink-0.buy-tokens__image.buy-tokens__image-2(src="/assets/img/tokens/tokens-2.webp" width="253" height="195" alt="Tokens")
						div
							span.d-block.h3.buy-tokens__quantity
								span {{ `${tokensTwoTitle} ` }}
								span.buy-tokens__quantity-name {{ $t('tokens.quantityTokens') }}
							.d-flex.flex-wrap.align-items-center.buy-tokens__prices
								span.text-bold.buy-tokens__price(v-html="currencyRUB ? tokensTwoRUB : tokensTwoUSD")
								span.b1.buy-tokens__price-old
					span.btn.btn-primary.buy-tokens__button(@click="$emit('buy', {currencyRUB: currencyRUB, product: {name: `${tokensTwoTitle} ${$t('tokens.quantityTokens')}`, cost: currencyRUB ? tokensTwoRUB : tokensTwoUSD}})") {{ $t('forms.buy') }}
			.buy-tokens__col
				.d-flex.justify-content-center.align-items-center.buy-tokens__discount.is-orange
					span.b2.text-bold {{ $t('tokens.benefit') }} {{ discountTokensOne }}
				.border-rounded.buy-tokens__wrap(:class="{'is-dark': isDarkTheme}")
					.buy-tokens__inner
						img.flex-shrink-0.buy-tokens__image.buy-tokens__image-3(src="/assets/img/tokens/tokens-3.webp" width="253" height="207" alt="Tokens")
						div
							span.d-block.h3.buy-tokens__quantity
								span {{ `${tokensThreeTitle} ` }}
								span.buy-tokens__quantity-name {{ $t('tokens.quantityTokens') }}
							.d-flex.flex-wrap.align-items-center.buy-tokens__prices
								span.text-bold.buy-tokens__price(v-html="currencyRUB ? tokensThreeRUB : tokensThreeUSD")
								span.b1.buy-tokens__price-old(v-html="currencyRUB ? tokensThreeOldRUB : tokensThreeOldUSD")
					span.btn.btn-primary.buy-tokens__button(@click="$emit('buy', {currencyRUB: currencyRUB, product: {name: `${tokensThreeTitle} ${$t('tokens.quantityTokens')}`, cost: currencyRUB ? tokensThreeRUB : tokensThreeUSD, discount: discountTokensOne, colorDiscount: 'orange', oldCost: currencyRUB ? tokensThreeOldRUB : tokensThreeOldUSD}})") {{ $t('forms.buy') }}

			.buy-tokens__col
				.d-flex.justify-content-center.align-items-center.buy-tokens__discount.is-green
					span.b2.text-bold {{ $t('tokens.benefit') }} {{ discountTokensTwo }}
				.border-rounded.buy-tokens__wrap(:class="{'is-dark': isDarkTheme}")
					.buy-tokens__inner
						img.flex-shrink-0.buy-tokens__image.buy-tokens__image-4(src="/assets/img/tokens/tokens-4.webp" width="253" height="208" alt="Tokens")
						div
							span.d-block.h3.buy-tokens__quantity
								span {{ `${tokensFourTitle} ` }}
								span.buy-tokens__quantity-name {{ $t('tokens.quantityTokens') }}
							.d-flex.flex-wrap.align-items-center.buy-tokens__prices
								span.text-bold.buy-tokens__price(v-html="currencyRUB ? tokensFourRUB : tokensFourUSD")
								span.b1.buy-tokens__price-old(v-html="currencyRUB ? tokensFourOldRUB : tokensFourOldUSD")
					span.btn.btn-primary.buy-tokens__button(@click="$emit('buy', {currencyRUB: currencyRUB, product: {name: `${tokensFourTitle} ${$t('tokens.quantityTokens')}`, cost: currencyRUB ? tokensFourRUB : tokensFourUSD, discount: discountTokensTwo, colorDiscount: 'green', oldCost: currencyRUB ? tokensFourOldRUB : tokensFourOldUSD}})") {{ $t('forms.buy') }}

</template>

<script>
export default {
	name: 'BuyTokens',
	props: {
		isDarkTheme: {
			type: Boolean,
			default: false
		},
		plans: {
			type: Array,
			default: () => ([])
		},
		currencyRUB: {
			type: Number,
			default: 0
		}
	},
	computed: {
		tokensOneRUB() {
			return this.plans.find(x => x.tokens == this.tokenCoins[0] && x.isCurrencyRUB()).price.priceWithCurrency();
		},
		tokensTwoRUB() {
			return this.plans.find(x => x.title != 'Monthly' && x.title != 'Annual' && x.title != 'Quarterly' && x.tokens == this.tokenCoins[1] && x.isCurrencyRUB()).price.priceWithCurrency();
		},
		tokensThreeRUB() {
			return this.plans.find(x => x.tokens == this.tokenCoins[2] && x.isCurrencyRUB()).price.priceWithCurrency();
		},
		tokensThreeOldRUB() {
			return this.plans.find(x => x.tokens == this.tokenCoins[2] && x.isCurrencyRUB()).priceFull.priceWithCurrency();
		},
		tokensFourRUB() {
			return this.plans.find(x => x.tokens == this.tokenCoins[3] && x.isCurrencyRUB()).price.priceWithCurrency();
		},
		tokensFourOldRUB() {
			return this.plans.find(x => x.tokens == this.tokenCoins[3] && x.isCurrencyRUB()).priceFull.priceWithCurrency();
		},
		tokensOneUSD() {
			return this.plans.find(x => x.tokens == this.tokenCoins[0] && !x.isCurrencyRUB()).price.priceWithCurrency();
		},
		tokensTwoUSD() {
			return this.plans.find(x => x.title != 'Monthly' && x.title != 'Annual' && x.title != 'Quarterly' && x.tokens == this.tokenCoins[1] && !x.isCurrencyRUB()).price.priceWithCurrency();
		},
		tokensThreeUSD() {
			return this.plans.find(x => x.tokens == this.tokenCoins[2] && !x.isCurrencyRUB()).price.priceWithCurrency();
		},
		tokensThreeOldUSD() {
			return this.plans.find(x => x.tokens == this.tokenCoins[2] && !x.isCurrencyRUB()).priceFull.priceWithCurrency();
		},
		tokensFourUSD() {
			return this.plans.find(x => x.tokens == this.tokenCoins[3] && !x.isCurrencyRUB()).price.priceWithCurrency();
		},
		tokensFourOldUSD() {
			return this.plans.find(x => x.tokens == this.tokenCoins[3] && !x.isCurrencyRUB()).priceFull.priceWithCurrency();
		},
		/*Title plan amount*/
		tokensOneTitle() {
			return this.plans.find(x => x.tokens == this.tokenCoins[0]).tokens;
		},
		tokensTwoTitle() {
			return this.plans.find(x => x.title != 'Annual' && x.title != 'Quarterly' && x.tokens == this.tokenCoins[1]).tokens;
		},
		tokensThreeTitle() {
			return this.plans.find(x => x.tokens == this.tokenCoins[2]).tokens.toLocaleString('ru');
		},
		tokensFourTitle() {
			return this.plans.find(x => x.tokens == this.tokenCoins[3]).tokens.toLocaleString('ru');
		}
	}
}

</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.buy-tokens {
	margin-top: 23px;

	@include respond-below(lg) {
		margin-right: 24.5px;
		margin-left: 24.5px;
	}

	@media (max-width: 768px) {
		margin-bottom: 34.5px;
		margin-right: 12.5px;
		margin-left: 12.5px;
	}

	@include respond-below(xs) {
		max-width: 420px;
		margin-right: auto;
		margin-left: auto;
	}

	&__col {
		position: relative;
		width: calc(100% / 4 - (3 / 4 * 24px));

		@include respond-below(lg) {
			width: calc(100% / 2 - (1 / 2 * 43px));
			margin-top: 43px;
		}

		@media (max-width: 650px) {
			width: calc(100% / 2 - (1 / 2 * 28px));
		}

		@include respond-below(xs) {
			width: 100%;
			margin-top: 25px;

			&:has(.buy-tokens__discount) {
				.buy-tokens__wrap {
					border-radius: 0 0 8px 8px !important;
				}
			}
		}
	}

	&__wrap {
		height: 100%;
		padding: 20px;
		background-color: rgba(#ffffff, .2);

		@include respond-below(xs) {
			border-radius: 8px !important;
			padding: 16px 17px;
			height: auto;
		}

		&.is-dark {
			background-color: rgba(#000000, .2);
		}
	}

	&__inner {
		@include respond-below(xs) {
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
		}
	}

	&__discount {
		position: absolute;
		top: -15px;
		left: 0;
		height: 34px;
		width: 100%;
		text-align: center;
		border-radius: 8px 8px 0px 0px;
		color: var(--foreground-color);

		@include respond-below(xs) {
			position: relative;
			top: 1px;
		}

		&.is-orange {
			background-color: #FCB64D;
		}

		&.is-green {
			background-color: #59CB94;
		}
	}

	&__image {
		@include respond-below(lg) {
			margin-left: auto;
			margin-right: auto;
			display: block;
		}

		@include respond-below(sm) {
			height: auto;
		}

		@include respond-below(xs) {
			margin-right: 0;
		}

		&-1, &-2 {
			@include respond-below(sm) {
				width: 214px;
				margin-top: -15px;
				margin-bottom: -27px;
			}

			@include respond-below(xs) {
				width: 157px;
			}
		}

		&-1 {
			@include respond-below(xs) {
				margin-right: -30px;
				margin-top: -53px;
			}
		}

		&-2 {
			@include respond-below(xs) {
				margin-right: -29px;
				margin-top: -43px;
			}
		}

		&-3 {
			margin-bottom: -12px;

			@include respond-below(sm) {
				width: 179px;
				margin-top: -10px;
				margin-bottom: -14px;
			}

			@include respond-below(xs) {
				width: 141px;
				margin-right: -14px;
				margin-top: -26px;
				margin-bottom: 10px;
			}
		}
		&-4 {
			margin-bottom: -13px;

			@include respond-below(sm) {
				width: 159px;
				margin-top: 1px;
				margin-bottom: -9px;
			}

			@include respond-below(xs) {
				width: 137px;
				margin-top: -16px;
				margin-bottom: 3px;
				margin-right: -12px;
			}
		}
	}

	&__quantity {
		margin-top: 10px !important;
		color: #fff;

		@include respond-below(xs) {
			margin-top: 0 !important;
		}

		&-name {
			@include respond-below(xs) {
				font-size: 14px;
				color: #CBD2D8;
			}
		}
	}

	&__prices {
		margin-top: 16px;
		margin-bottom: 31px;

		@include respond-below(xs) {
			margin-bottom: 25px;
		}
	}

	&__price {
		font-size: 36px;
		line-height: 28px;
		margin-right: 12px;
		color: #fff;

		@media (max-width: 650px) {
			font-size: 24px;
			line-height: 28px;
		}

		&-old {
			position: relative;
			display: block;
			color: #fff;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: block;
				background: center / contain no-repeat url("data:image/svg+xml,%3Csvg width='58' height='14' viewBox='0 0 58 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 12.5L56.5 1' stroke='%23FF7C7C' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
			}
		}
	}

	&__button {
		color: var(--foreground-color) !important;

		@include respond-below(sm) {
			height: 50px;
		}
	}

	&__can-do {
		display: flex;
		background-color: rgba(255, 255, 255, 0.2);
		padding: 20px 21px;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		margin-top: 1px;
		color: white;
		margin-bottom: 43px;

		@include respond-below(lg) {
			margin-bottom: 0px;
		}

		@include respond-below(xs) {
			padding: 16px 17px;
			
			span {
				max-width: 198px;
			}
		}

		&:hover {
			color: white !important;
			text-decoration: none;
		}

		&:hover {
			background-color: rgba(255, 255, 255, 0.3);
		}

		&.is-dark {
			background-color: rgba(0, 0, 0, 0.2);

			&:hover {
				background-color: rgba(0, 0, 0, 0.3);
			}
		}
	}
}

</style>
