<template lang='pug'>
	.d-flex.flex-column.flex-md-row.justify-content-between.align-items-center.position-relative.footer-payment
		router-link.position-relative.text-decoration-none(to="/")
			img.mb-md-1.footer-payment__logo(src="/assets/img/current/logo-blue.svg")
			.text-grey.footer-payment__text(v-html="underLogoText")

		.d-flex.flex-column.flex-md-row.justify-content-center.align-items-center.position-relative.footer-payment__wrap
			.d-flex.align-items-center.mb-3(v-if="switches")
				locale-switch-simple.d-md-none.mr-3
				switches.d-md-none.payment-page__switch#tool-mode-switch(v-model='isDarkTheme')
			p.m-0.text-grey.footer-payment__rules-text(v-html="rightText")
</template>

<script>
import LocaleSwitchSimple from "@/components/Common/LocaleSwitchSimple";
import Switches from 'vue-switches'
import {defaultColor, colors} from '@/constants/config'

export default {
	components: {
		LocaleSwitchSimple,
		Switches
	},
	props: {
		underLogoText: {
			type: [String, Number],
			default: ''
		},
		rightText: {
			type: String,
			default: ''
		},
		switches: {
			type: Boolean,
			default: true
		},
		isDarkTheme: {
			type: Boolean,
			default: undefined
		}
	},
	methods: {
		getThemeColor() {
			return localStorage.getItem('themeColorPaymentPage') ?
				localStorage.getItem('themeColorPaymentPage') :
				defaultColor
		},
	},
	watch : {
		isDarkTheme(val) {
			let color = this.getThemeColor();
			let isChange = false;

			if (val) {
				isChange = true
				color = color.replace('light', 'dark')
			} else {
				isChange = true
				color = color.replace('dark', 'light')
			}

			if (isChange) {
				localStorage.setItem('themeColorPaymentPage', color)
				setTimeout(() => {
					window.location.reload()
				}, 500)
			}
		}
	},
}
</script>

<style lang='scss'>
@import '~@/assets/css/sass/_mixins.scss';

.footer-payment {
	width: 100%;
	max-width: 1440px;
	padding: 17px 109px 17px;
	margin-top: auto;

	&::after {
		content: '';
		position: absolute;
		z-index: 0;
		top: 0;
		bottom: 0;
		left: -100vh;
		right: -100vh;
		background-color: var(--grayscale-bg-dark-back);
	}

	&::before {
		content: '';
		position: absolute;
		z-index: 1;
		top: 0;
		left: -100vh;
		right: -100vh;
		height: 1px;
		background-color: var(--border-item-color);
	}

	@include respond-below(sm) {
		padding: 20px 0;

		&::before {
			left: -12px;
			right: -12px;
		}

		&::after {
			left: -12px;
			right: -12px;
		}
	}

	a,
	&__wrap {
		z-index: 1;
	}

	&__logo {
		display: block;
		width: 130px;
		height: 32px;

		@include respond-below(sm) {
			width: 142px;
			height: 35px;
			margin: 0 auto 15px;
		}
	}

	&__text {
		font-size: 12px;
		line-height: 14px;

		@include respond-below(sm) {
			text-align: center;
			margin-bottom: 15px;
		}
	}

	&__rules-text {
		max-width: 388px;
		font-size: 14px;
		line-height: 22px;
		text-align: end;

		a {
			position: relative;
			transition: color var(--animation-time-short);

			&:hover,
			&:active {
				color: var(--brand-primary) !important;
				text-decoration: underline;
			}
		}

		@include respond-below(sm) {
			text-align: center;
		}
	}

	&__switch {
		margin: 0 !important;
	}
}
</style>

