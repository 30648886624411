<template lang='pug'>
	.d-flex.justify-content-between.align-items-center.foreground-color.basic-box-shadow.border-rounded.header-payment(v-if="bot" :class="{'is-page-tokens' : isBuyTokens, 'is-dark': isDarkTheme}")
		.d-flex.align-items-center
			img.rounded-circle.flex-shrink-0.header-payment__logo(v-if="image" :src="image" alt="Bot Avatar" width="81" height="81")
			.d-flex.justify-content-center.align-items-center.rounded-circle.flex-shrink-0.header-payment__logo(v-if="!image")
				span.text-primary.text-bold.header-payment__logo-txt {{ bot.firstName.replace(/[^a-zA-Zа-яА-Я0-9]/g, '').slice(0, 1).toUpperCase() }}

			div
				.d-flex.align-items-center.flex-wrap.header-payment__inner
					span.text-bold.d-block.header-payment__name(:class="{'is-white' : isBuyTokens}") {{ bot.firstName }}
					span.b1.header-payment__login(:class="{'is-white' : isBuyTokens, 'text-grey': !isBuyTokens}") @{{ bot.username }}
				p.b3.header-payment__txt(v-if="bot.shortDescription" :class="{'is-white' : isBuyTokens, 'text-grey': !isBuyTokens}" v-html="bot.shortDescription")

		b-tabs.header-payment__tabs(v-if="isBuyTokens" v-model="currencyRUB" nav-class='tabs-filled tabs-filled-transparent p-0 tabs-tariffs' no-fade)
			b-tab
				template(v-slot:title)
					.d-flex.align-items-center
						span.header-payment__tab(:class="{'is-page-tokens' : isBuyTokens}") USD
						img(src="/assets/img/icons/american-flag.png" alt="American Flag" width="15" height="auto")

			b-tab(active)
				template(v-slot:title)
					.d-flex.align-items-center
						span.header-payment__tab(:class="{'is-page-tokens' : isBuyTokens}") RUB
						img(src="/assets/img/icons/russian-flag.png" alt="Russian Flag" width="15" height="auto")

</template>

<script>
export default {
	name: 'HeaderPaymentBot',
	props: {
		bot: {
			type: Object,
			default: () => ({})
		},
		image: {
			type: String,
			default: ''
		},
		isBuyTokens: {
			type: Boolean,
			default: false
		},
		isDarkTheme: {
			type: Boolean,
			default: false
		},
	},
	data: () => ({
		currencyRUB: 0,
	}),
	watch: {
		currencyRUB(nV) {
			if (nV == 0) {
				this.$emit('currencyRUB', 0)
			} else if (nV == 1) {
				this.$emit('currencyRUB', 1)
			}
		}
	}
}

</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.header-payment {
	padding: 20px 17px;

	@include respond-below(xs) {
		padding: 15px 17px;
	}

	&.is-page-tokens {
		background-color: rgba(#FFFFFF, .2) !important;
		box-shadow: none !important;

		@include respond-below(lg) {
			margin-right: 24.5px;
			margin-left: 24.5px;
		}

		@media (max-width: 768px) {
			margin-right: 12.5px;
			margin-left: 12.5px;
		}

		@include respond-below(xs) {
			max-width: 420px;
			margin: 0 auto;
		}

		@include respond-below(xm) {
			flex-wrap: wrap;
		}

		&.is-dark {
			background-color: rgba(#000000, .2) !important;
		}
	}

	&__logo {
		margin-right: 20px;
		width: 81px;
		height: 81px;
		background-color: var(--brand-bg-primary);

		@include respond-below(xs) {
			margin-right: 10px;
			width: 77px;
			height: 77px;
		}

		&-txt {
			font-size: 32px;
		}
	}

	&__name {
		margin-right: 11px;
		font-size: 18px;
		line-height: calc(28 / 18);
		word-break: break-word;

		&.is-white {
			color: #fff;
		}
	}

	&__login {
		margin-top: -1px;

		&.is-white {
			color: #fff;
		}
	}

	&__txt {
		margin-top: 10px;
		margin-bottom: 0;
		word-break: break-word;

		@include respond-below(xs){
			margin-top: 6px;
		}

		&.is-white {
			color: #fff;
		}
	}

	&__inner {
		@include respond-below(xs){
			margin-top: 2px;
		}
	}

	&__tabs {
		margin-left: 20px;

		@include respond-below(xm) {
			margin-left: 101px;
			margin-top: 10px;
			margin-bottom: 5px;
		}

		@include respond-below(xs) {
			margin-left: 87px;
		}
	}

	&__tab {
		color: var(--brand-color-discount-year);
		margin-right: 5px;
	}

	.tabs-filled .nav-link.active .header-payment__tab {
		color: #52A7F9;
	}
}
</style>
