<template>
	<noscript><div><img v-if='id' :src="link" style="position:absolute; left:-9999px;" alt="" /></div></noscript>
</template>

<script>
	export default {
		name : 'VkADS',
		props : {
			id: String,
			ev: {
				type: String,
				default: 'pageView'
			}
		},
		data : () => ({

		}),
		created () {
			let id = this.id;
			var _tmr = window._tmr || (window._tmr = []);
			_tmr.push({id: id, type: 'pageView', start: (new Date()).getTime()});
            _tmr.push({ type: 'reachGoal', id: id, goal: this.ev});
			(function (d, w, id) {
				if (d.getElementById(id)) return;
				var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
				ts.src = (d.location.protocol == "https:" ? "https:" : "http:") + "//top-fwz1.mail.ru/js/code.js";
				var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
				if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
			})(document, window, "topmailru-code");
		},
		computed : {
			link () {
				return '//top-fwz1.mail.ru/counter?id='+ this.id +';js=na';
			}
		}
	}
</script>
