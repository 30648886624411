<template lang='pug'>
	div
		router-link.d-flex.justify-content-between.align-items-center.cursor-pointer.pw-btn-link.text-decoration-none(
			v-if="link && !href"
			:to="link"
			:class="[{'pw-btn-link--dark' : isDarkColor}, `${icon}`, {'btn-tooltip': tooltip}]"
			:target='`${blank ? "_blank" : false}`'
			tag='a'
		)
			span.b3.d-flex.align-items-center.mr-1(v-html="text")
			svg.ml-2(width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg")
				path(d="M10.7129 3.75L15.7507 9L10.7129 14.25" stroke="#52A7F9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
				line(x1="14.5488" y1="9.27344" x2="3.24903" y2="9.27344" stroke="#52A7F9" stroke-width="2" stroke-linecap="round")

		a.d-flex.justify-content-between.align-items-center.cursor-pointer.pw-btn-link.text-decoration-none(
			v-if="href"
			:href="href"
			:class="[{'pw-btn-link--dark' : isDarkColor}, `${icon}`, {'btn-tooltip': tooltip}]"
			:target='`${blank ? "_blank" : false}`'
		)
			span.b3.d-flex.align-items-center.mr-1(v-html="text")
			svg.ml-2(width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg")
				path(d="M10.7129 3.75L15.7507 9L10.7129 14.25" stroke="#52A7F9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
				line(x1="14.5488" y1="9.27344" x2="3.24903" y2="9.27344" stroke="#52A7F9" stroke-width="2" stroke-linecap="round")
</template>
<script>

export default {
	name: 'PwBtnLink',
	props: {
		text: {
			default : '',
			type : String
		},
		link: {
			type: Object,
			default: () => ({})
		},
		href: {
			type: String,
			default: ''
		},
		icon: {
			default : '',
			type: String
		},
		blank: {
			default: false,
			type: Boolean
		},
		tooltip: {
			default: false,
			type: Boolean
		}
	}
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.pw-btn-link {
	padding: 14px 34px;
	color: var(--main-text-color);
	background-color: var(--light-primary);
	border-radius: var(--border-radius-rounded);
	transition: var(--animation-time-short);

	&:hover,
	&:active {
		background-color: var(--foreground-color);
		box-shadow: var(--box-shadow-hover);
		color: var(--main-text-color) !important;
	}

	@include respond-below(sm) {
		padding: 14px 26px;
	}

	&.no-icon {
		padding: 14px 16px;
	}

	&.icon-document {
		@include respond-above(sm) {
			position: relative;
			padding: 14px 16px 14px 50px;

			&::before {
				content: '';
				position: absolute;
				top: 50%;
				left: 16px;
				width: 18px;
				height: 18px;
				mask-image: url('/assets/img/icons/document.svg');
				background-color: var(--brand-primary);
				mask-size: cover;
				transform: translateY(-50%);
			}
		}
	}

	&.btn-tooltip {
		padding: 10px 16px;
		width: fit-content;
		white-space: nowrap;

		@include respond-below(sm) {
			padding: 13px 20px;
			max-width: 100%;
			width: 100%;
		}
	}
}
</style>
