<template lang='pug'>
	div(v-if='remainingTime')
		img.mr-2(:src="'/assets/img/icons/alarm-clock.svg'" v-if='remainingTime.days == 0')
		img.mr-2(:src="'/assets/img/icons/light-calendar.svg'" v-else)
		span.countdown-timer(v-if='remainingTime.days == 0') {{ remainingTime.hours }}:{{ remainingTime.minutes }}:{{ remainingTime.seconds }}
		span.countdown-timer(v-else) {{ $t('forms.until') }} {{ targetDate.format('DD.MM.YYYY') }}
</template>

<script>
import moment from 'moment-timezone';

export default {
	props: {
		until: {
			default: '',
			type: String
		}
	},
	data() {
		return {
			countdownInterval: null,
			remainingTime: null
		};
	},
	mounted() {
		this.startCountdown();
	},
	computed: {
		targetDate() {
			if ( this.until == '' ) return null;

			return moment(this.until).add(-3,'hours');
		}
	},
	methods: {
		startCountdown() {
			this.countdownInterval = setInterval(() => {
				const difference = this.targetDate.diff(moment(new Date().getTime()));

				if (difference > 0) {
					this.remainingTime = this.calculateTime(difference);
				} else {
					this.remainingTime = null;
					clearInterval(this.countdownInterval);
					this.done();
				}
			}, 1000);
		},
		calculateTime(timeDifference) {
			const seconds = Math.floor((timeDifference / 1000) % 60);
			const minutes = Math.floor((timeDifference / 1000 / 60) % 60);
			const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
			const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

			return { days, hours: hours < 10 ? '0'+ hours : hours, minutes: minutes < 10 ? '0'+ minutes : minutes, seconds: seconds < 10 ? '0'+ seconds : seconds };
		},
		done() {
			this.$emit('doneTimer', true)
		}
	}
};
</script>

<style lang="scss" scoped>
.countdown-timer {
	display: inline-flex;
	min-width: 65px;
}
</style>
