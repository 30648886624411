<template lang='pug'>
	b-card.no-border.flex-column.flex-sm-row.justify-content-between.align-items-sm-center.discount-card(v-if="card" no-body :class="cardClass")
		.d-flex.align-items-center.justify-content-center.foreground-back.discount-card__promo-code
			span.b4.text-grey.mr-2.discount-card__title {{ title }}
			span.discount-card__discount -{{ card.discount }}%

		.d-flex.flex-column(v-if='plan')
			span.b1.mb-2.mt-4.overflow-wrap.mr-1.text-bold.tariff-card__title {{ plan.title }}
			.d-flex.align-items-center.mb-xl-0
				span.mr-2.text-bold.discount-card__price.tariff-card__amount(v-html="plan.priceWithDiscountWithCurrency(card.discount)")
				span.b3.mr-2.text-grey.discount-card__price--old(v-html="plan.price.priceWithCurrency()")
				span.b3.text-grey.discount-card__period {{ $t('plan.periods.' + plan.period.title) }}

		.d-flex.flex-column
			.btn.btn-success.btn-medium.discount-card__button(@click="$emit('click', $event)") {{ $t('forms.pay') }}
			.d-flex.align-items-center.discount-card__date(v-if='card.isShowPeriod && card.endAt')
				discount-timer(:until='card.endAt' @doneTimer='done')
</template>

<script>
import DiscountTimer from '@/components/UI/DiscountTimer'

export default {
	name: 'DiscountCard',
	components: {
		DiscountTimer
	},
	props: {
		card: {
			type: Object,
			default: () => ({})
		},
		plan: {
			type: Object,
			default: () => ({})
		},
		title: {
			type: String,
			default: ''
		},
		cardClass: {
			type: String,
			default: ''
		},
	},
	methods: {
		done() {
			this.$emit('done', this.card.id);
		}
	}
}
</script>

<style lang='scss' scoped>
@import '~@/assets/css/sass/_mixins.scss';

.discount-card {
	padding: 24px 20px 20px;
	background-color: var(--brand-bg-warning);
	margin-bottom: 10px;

	@include respond-above(xs) {
		padding-top: 15px;
		margin-bottom: 15px;
	}

	&__title {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 320px;

		@media(max-width: 1400px) {
			max-width: 270px;
		}

		@media(max-width: 1290px) {
			max-width: 250px;
		}

		@media(max-width: 1250px) {
			max-width: 220px;
		}

		@include respond-below(lg) {
			max-width: 264px;
		}
	}

	&__promo-code {
		position: absolute;
		top: 0;
		left: 0;
		padding: 7px 10px 5px;
		border-bottom-right-radius: var(--border-radius-rounded);
		box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.05);
		width: 100%;

		@media (min-width: 576px){
			width: auto;
			padding: 5px 10px;
		}
	}

	&__discount {
		height: max-content;
		font-size: 12px;
		line-height: 18px;
		padding: 2px 6px;
		color: var(--brand-color-discount);
		border-radius: 6px;
		background-color: var(--brand-color-warning);
	}

	&__price {
		color: var(--brand-color-warning);
		font-size: 24px;
		line-height: 28px;

		&--old {
			text-decoration: line-through;
			margin-top: 6px;
			margin-left: -2px;
		}
	}

	&__period{
		margin-top: 5px;
		margin-left: -3px;
	}

	&__button {
		min-width: 175px;
		margin-top: 20px;
		background-color: var(--brand-color-warning)!important;

		@media (min-width: 576px){
			margin-top: 5px;
		}

		&.btn:hover{
			background-color: #FC884D !important;
		}

		&.btn:active {
			background: linear-gradient(114deg, #FCB64D	 0%, #FC884D 100%) !important;
		}
	}

	&__date {
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 175px;
		height: 42px;
		font-size: 16px;
		line-height: 16px;
		padding: 16px;
		border-radius: var(--border-radius-rounded);
		background-color: var(--foreground-color);
		margin-top: 11px;

		img {
			width: 24px;
			height: 24px;
		}
	}

	&.promotion {
		background-color: var(--brand-bg-promotion);

		.discount-card__discount {
			background: var(--brand-bg-gradient-purple);
		}

		.discount-card__price{
			color: var(--brand-light-gradient-secondary);
		}

		.discount-card__button {
			background-color: var(--brand-light-gradient-secondary) !important;
			background: var(--brand-bg-gradient-purple);

			&:hover{
				background: none;
				background-color: #68259E !important;
			}

			&:active {
				background: linear-gradient(114deg, #A494F8 0%, #DA91FF 100%) !important;
			}
		}
	}
}
</style>
