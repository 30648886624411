<template lang='pug'>
	b-modal(id="add-promo-code" ref='modal' centered content-class="payment-page__add-promo" header-class="d-flex p-0 border-0 align-items-center mb-4" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
		template(#modal-header="{ close }")
			span.b1 {{ $t('paymentPage.addPromoCode') }}
			icon-close(@click="close()")
		my-input(
			v-model="value"
			:label="$t('paymentPage.promoCode')"
			:errorFeedback="error"
			@blur='validationPromoCode'
			@input='validationPromoCode'
		)
		span.btn.btn-primary.w-100.mt-3(@click="sendPromoCode" :class="{'disabled': isDisabled || isloading}")
			.loader(v-if="isloading")
			span(v-else) {{ $t('paymentPage.apply') }}
</template>

<script>
import MyInput from "@/components/UI/MyInput";
import IconClose from "@/components/Common/IconClose";
import { mapActions } from 'vuex';
import moment from 'moment-timezone';

export default {
	name: 'ModalPromtPromocode',
	components: {
		MyInput,
		IconClose,
	},
	props: {
		hashid: ''
	},
	data: () => ({
		value: null,
		error: null,
		isDisabled: false,
		isloading: false
	}),
	created () {
		this.validationPromoCode();
	},
	mounted () {
		this.$root.$on('bv::modal::hide', () => {
			this.value = null;
			this.isloading = false;
			this.error = '';
		});
	},
	methods : {
		...mapActions({
			check: 'project/checkPromocode'
		}),
		close() {
			this.$refs.modal.hide();
			this.value = null;
			this.isloading = false;
		},
		async sendPromoCode() {
			this.isloading = true;
			this.check({hashid: this.hashid, code: this.value})
				.then( v => {
					if ( v && v.id ) {
						this.$emit('onPromt', v);
						this.$notify('success', `😊 &nbsp;&nbsp;&nbsp;${this.$t('paymentConfirm.promoCodePromt')}`);
						return this.close();
					}

					this.error = this.$t('promotions.form.doesNotExistPromocode');
					this.isloading = false;
				})
		},
		validationPromoCode() {
			const regex = /^[a-z0-9]+$/i;

			this.isDisabled = !(this.value != null && this.value != '');
			this.error = this.value == '' ? this.$t('promotions.form.error') : '';

			if (this.value && this.value.length > 0) {
				if (!regex.test(this.value)) {
					this.isDisabled = true
					this.error = this.$t('errors.alpha.enNumbers');
				} else {
					this.error = '';
					this.isDisabled = false;
				}
			}
		}
	}
}
</script>
